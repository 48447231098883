.mat-drawer-content.mat-drawer-content {
  z-index: auto;
}

.mat-drawer-container.mat-drawer-container {
  z-index: auto;
}

.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
}

mat-sidenav.mat-drawer.mat-drawer-side {
  background-color: theme('colors.slate.50');

  z-index: 3;
}
